.phone
  .content
    justify-content: flex-start
  .titleContainer
    margin-top: 60px
    margin-bottom: 40px
  .title
    padding: 0px 16px
  .flags
    flex-direction: column
    width: 100%
    padding: 16px
  .flag
    margin: 0
    width: 100%
    margin-bottom: 24px
    border-radius: 16px
    padding: 14px 16px
    display: flex
    justify-content: space-between
    align-items: center
    height: auto
  .imgflag
    width: 60px
  .flagname
    display: flex
    align-items: center
  .countryName
    box-sizing: border-box
    padding-left: 16px
    font-size: 20px
  .imageBackground
    align-items: flex-start
    justify-content: flex-start
  .world
    height: auto

.Home
  width: 100%
  height: 100vh
  background-color: var(--white)
  background-size: 600px
  position: relative
  font-family: var(--primary-font)

.imageBackground
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  opacity: 0.3
  display: flex
  justify-content: center
  align-items: center

.world
  height: 100%
  max-width: 100%
  opacity: 0.5

.header
  background-color: var(--primary-colorblue)
  width: 100%
  height: 100px
  border-bottom: solid 4px var(--primary-colorgold)
  display: flex
  justify-content: center
  align-items: center
  background-image: linear-gradient(#002747, #01192A)

.imglogo
  height: 100%
  padding: 16px

.content
  position: relative
  width: 100%
  height: calc(100% - 110px)
  min-height: 500px
  display: flex
  flex-direction: column
  align-items: center

.titleContainer
  margin: 80px 0px 32px

.title
  text-align: center
  font-size: 24px

.flags
  display: flex
  justify-content: center
  position: relative

.flag
  box-sizing: border-box
  width: 144px
  height: 144px
  margin: 18px
  cursor: pointer
  background-color: var(--white)
  transition: all 200ms ease-out
  border-radius: 72px
  box-shadow: 0px 0px 16px -8px rgba(0,0,0,0.7)
  display: flex
  justify-content: center
  align-items: center

  &:hover
    transform: scale(1.07)

.imgflag
  width: 100px
  display: block
