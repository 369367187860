body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  /* __________________________________________ Colors */
  --white: #ffffff;
  --yellow: #f8e35e;
  --primary-colorblue: #002747;
  --secondary-colordarkblue: #163254;
  --primary-colorgold: #9b722a;
  --primary-colorgray: #f1f3f4;
  --secondary-colorblue: #04f6e8;
  --secondary-cyancolorblue: #5ad7d0;
  --primary-textcolor: rgb(5, 5, 5);
  --color-disabled: #959495;
  --color-gray: #595959;
  --coloer-border-gray: #989898;
  --coloer-border-gray-lite: #cccccc;
  --color-invalid: #eb5d0b;
  --red-alert: red;
  --chart-line-green: #58a300;

  /* ___________________________________________ Fonts */
  --primary-font: Raleway;
}
